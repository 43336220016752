import util from "lib/util"
export default {
  data(){
    return {
      auth: true,
      showUnpleased: false,
      finalComment: [],
      commentText: '',
      chooseIndex: null,
      commentList: [{name:'穿着/谈吐不得体',value:false},{name:'服务态度恶劣',value:false},{name:'没有按时上门',value:false},{name:'多/乱收费',value:false},{name:'乱回填上门时间',value:false},{name:'电话索要取件码',value:false}],
      expid: util.query("expid"),
      dispatchid: util.query("dispatchid"),
      detail: {},
      courier: {},
      market: {},
      showHelp: false,
      showDetail: false,
      showStatus: false,
      showCancel: false,
      showCode: false,
      showCtrl: false,
      ctrlTop: 0,
      waitText: '',
      evaluate: {
        show: false,
        offset: '100%',
        hasDo: false,
        val: -1,
        answer: -1,
        questions: [],
        question: {}
      },
      showEvaluateTips: false,
      comments: []
    }
  },
  computed: {
    statusText: function () {
      var detail = this.detail
      switch (detail.tabId) {
        case 0: //待预支付
          return detail.paycost ? "优惠券已抵扣" + detail.paycost + "元" : ''
        case 1: //待派发
        case 2:
          return this.waitText
        case 3: //已接单（需判断是否填写取件时间）
          return detail.filldoortime ? '预约上门时间：<span class="txt-warn">' + detail.doorTime + "</span>" + (detail.disdoortime < 0 ? "<span class='txt-danger'> 已超时</span>" : "") : "待回填约定取件时间"
        case 4:
        case 7:
          return "取消时间：" + detail.canceltime
        case 5: //已签收或者在途中
        case 6:
          return detail.transStatusName
        case 10:
          return "取件时间：" + detail.gottime
      }
    },
    showComplaint: function () {
      var detail = this.detail
      return (detail.tabId == 4 || detail.tabId == 7) && (detail.costcomplain == 'Y' || detail.complainable == 'Y')
    },
    wait: function () {
      var minutes = Math.floor(+this.detail.waittime / 60),
        seconds = +this.detail.waittime % 60
      minutes = minutes < 10 ? "0" + minutes : minutes
      seconds = seconds < 10 ? "0" + seconds : seconds
      if (minutes >= 10) {
        this.waitText = "周围快递员比较繁忙，请稍后",
        this.showCancel = true
      }
      return minutes + ":" + seconds
    },
    showMap: function () {
      return this.detail.tabId === 3 && +this.detail.disdoortime / 60 < 1
    },
    outService: function () {
      var flag = false;
      var services = (this.market.serviceTimeNew || '').split("-")
      var now = new Date(),
        nowstr = ('0' + now.getHours()).slice(-2) + ":" + ('0' + now.getMinutes()).slice(-2)
      if (nowstr > services[0] && nowstr < services[1]) {
        this.waitText = "预计联系时间10分钟"
      } else {
        if (nowstr < services[0]) {
          flag = true;
          this.waitText = "预计联系时间今天" + services[0]
        } else if (nowstr > services[1]) {
          flag = true;
          this.waitText = "预计联系时间明天" + services[0]
        } else {
          this.waitText = "预计联系时间10分钟"
        }
      }
      flag && this.timer && clearInterval(this.timer)
      return flag
    },
    leftPaytime: function () {
      return ('0' + ~~(this.detail.premanenttime / 60)).slice(-2) + '分' + ('0' + (~~this.detail.premanenttime % 60)).slice(-2) + '秒'
    }
  },
  methods: {
    goRealname() {
      this.navigator.push({
        name: "auth"
      })
    },
    getAuth: function() {
      this.$http.get("/apicenter/order.do?method=queryUserCardInfo").then(result => {
        if (result.data) {
          this.auth = true
        } else {
          this.auth = false
        }
      })
    },
    finishComment: function () {  // 点击完成（不满意时）
      var arr = []
      if(!this.finalComment.length && !this.commentText) { // 没评价
        this.$tosat("请选择您不满意的原因")
        return false
      } else {
        if(this.finalComment.length) {
          arr = this.finalComment.slice(0)
        }
        if(this.commentText) {
          arr.push(this.commentText.replace(/,/g, '，'))
        }
        this.comments = arr
        this.evaluateCourier()
      }
    },
    toggleComment: function (index) { // 点击不满意的标签
      this.commentList[index].value = !this.commentList[index].value
    },
    evaluateCourier: function () { // 新评价接口
      var data = {
        expid: this.expid,
        dispatchid: this.dispatchid,
        flag1: this.evaluate.val
      }
      this.evaluate.val == 1 ? null : data.comments = this.comments.join(',')
      this.$http.post("/apicenter/order.do?method=evaluateTxtCourier", {
        data: data
      }).then(() => {
        this.detail.iseval = true
        if(this.evaluate.val == 1) { // 满意
          this.$toast("感谢您的评价，期待再次为您服务")
        } else {
          this.showUnpleased = true
          this.$toast("感谢您的反馈，我们会更加努力提升服务")
        }
        this.detail.evalmsg = this.evaluate.val ? '满意' : '不满意'
        this.toggleEvaluate()
      })
    },
    getDetail: function (broadcast) {
      !broadcast && this.showLoading()
      this.$http.get("/apicenter/order.do?method=getOrderInfo", {
        data: {
          dispatchid: this.dispatchid,
          expid: this.expid
        }
      }).then(result => {
        let detail = result.detail
        this.detail = detail
        this.detail.evalmsg === '不满意' ? this.comments = result.detail.evaluateInfo : null
        this.courier = result.courierinfo || {}
        this.market = result.dispatchinfo || {}
        this.generateTimer()
        this.getQuestion()
        !broadcast && this.hideLoading()
      }).catch(res=> {
        this.$toast(res.message)
        !broadcast && this.hideLoading()
      })
    },
    generateTimer: function () {
      this.timer && clearInterval(this.timer)
      if (this.detail.tabId === 0 && +this.detail.premanenttime > 0) { //预支付计时器
        this.timer = setInterval(() => {
          this.detail.premanenttime = +this.detail.premanenttime - 1
          if (this.detail.premanenttime <= 0) {
            clearInterval(this.timer)
            this.getDetail()
          }
        }, 1000)
      } else if (this.detail.tabId == 1 || this.detail.tabId == 2) { //
        this.timer = setInterval(() => {
          this.detail.waittime = +this.detail.waittime + 1
        }, 1000)
      } else if (this.detail.tabId === 3) {
        this.timer = setInterval(() => {
          this.detail.disdoortime = +this.detail.disdoortime - 1
          if (this.detail.disdoortime < 0) {
            clearInterval(this.timer)
          }
        }, 60000)
      }
    },
    getQuestion: function () {
      if ((this.detail.tabId == 10 || this.detail.tabId == 5 || this.detail.tabId == 6) && !+this.detail.iseval && (this.detail.price === 0 || this.detail.paystatus === 'PAYED')) {
        this.$http.get("/apicenter/order.do?method=evallist").then(result => {
          this.evaluate.questions = [result.data2, result.data]
          this.toggleEvaluate()
        })
      }
    },
    doEvaluate: function (v) {
      this.evaluate.val = v
      v == 1 ? this.evaluateCourier() : this.evaluate.hasDo = true
    },
    toggleEvaluate: function () {
      if (this.evaluate.show) {
        this.evaluate.offset = '100%'
        this.evaluate.val = -1
        this.evaluate.hasDo = false
        setTimeout(() => {
          this.evaluate.show = false
        }, 300)
      } else {
        this.evaluate.show = true
        setTimeout(() => {
          this.evaluate.offset = 0
        }, 0)
      }
    },
    toggleDetail: function () {
      this.showDetail = !this.showDetail
    },
    cancel() {
      this.$broadcastChannel.$on("dispatchDetail", () => {
        this.getDetail(true)
      })
      this.navigator.push({
        name: "cancel",
        params: {
          event: "dispatchDetail",
          order: this.detail
        }
      })
    },
    pay(view) {
      this.navigator.push({
        name: "dispatchPay",
        query: {
          expid: this.detail.expid,
          dispatchid: this.detail.dispatchId,
          view: view === true ? 1 : 0
        },
        params: {
          source: 'dispatchDetail'
        }
      })
    },
    showLoading: function (text) {
      this.loading = this.$loading(text)
    },
    hideLoading: function() {
      this.loading.hide()
    },
    holdon: function () {
      this.$http.post("/apicenter/order.do?method=waitagin", {
        data: {
          dispatchid: this.dispatchid,
          expid: this.expid
        }
      })
    },
    showCodeHelp: function () {
      this.$confirm({
        content: "取件码是配送员确认取件的唯一验证，请确认配送员取件后再告诉配送员，以免造成损失",
        type: "alert",
        confirmText: "我知道了"
      })
    },
    nightfeeTip: function () {
      this.$confirm({
        content: "若快递员没有在当晚约定时间前取件，夜间取件费将在取件后退还至您的账户",
        type: "alert",
        confirmText: "我知道了"
      })
    },
    reminder: function () {
      this.$http.post("/apicenter/order.do?method=reminder", {
        data: {
          expid: this.expid
        },
      }).then(() => {
        this.$toast("已催单")
      })
    },
    reOrder: function (cargo) {
      util.setSession("reOrderAddress", {
        rec: {
          xzqName: this.detail.recxzq.replace(/#/g, ','),
          address: this.detail.recaddr,
          phone: this.detail.recmobile,
          name: this.detail.recName
        },
        send: {
          xzqName: this.detail.sendxzq.replace(/#/g, ','),
          address: this.detail.sendaddr,
          phone: this.detail.sendmobile,
          name: this.detail.sendName,
        }
      })
      cargo && util.setSession('reOrderCargo', {
        cargodesc: this.detail.cargodesc ? this.detail.cargodesc.split(",") : [],
        cargo: {
          name: this.detail.cargo
        },
        weight: this.detail.weight,
        valins: this.detail.valinspay
      })
      util.setSession("reorder", "do")
      this.navigator.push({
        name: "dispatch"
      })
    },
    complaint: function (flag) {
      var word = flag === false ? "投诉" : "费用申诉"
      this.$confirm({
        content: word + "和查看" + word + "进度功能需要下载快递100APP，是否前往下载？",
        confirmText: "是",
        cancelText: "否"
      },  () => {
        util.downApp()
      })
    },
    goResult: function () {
      var detail = this.detail
      let query = this.$store.state.globalData.queryString
      location.href = "/result.jsp?com=" + detail.kuaidiCom + '&nu=' + detail.kuaidiNum + (query ? "&" + query : '')
    },
    downApp: function() {
      util.downApp()
    },
    appGuide() {
      AppGuide.dialog({
        title: "物流信息实时追踪提醒",
        logtype: 'orderDetail',
        iosLink: "ilovegirl/near?selectIndex=2&ordersource=mweb",
        androidLink: "ilovegirl/near?source=mweb",
        miniprogram: {
          path: "pages/orderindex/index",
          orderSrouce: "mweb"
        },
        plainBtn: {
          text: "继续使用"
        }
      })
    }
  },
  watch: {
    commentList: {
      handler:function(newVal){
        var arr = []
        for(var i in newVal) {
          if(newVal[i].value) {
            arr.push(newVal[i].name)
          }
        }
        this.finalComment = arr
      },
      deep:true
    },
    'evaluate.answer': function (val) {
      if (val == -1) return
      this.$http.post("/apicenter/order.do?method=evaluateCourier", {
        data: {
          expid: this.expid,
          dispatchid: this.dispatchid,
          flag1: this.evaluate.val,
          flag2: this.evaluate.answer,
          questionid: this.evaluate.question.id
        }
      }).then(() => {
        this.$toast("感谢您的评价，期待再次为您服务")
        this.detail.iseval = true
        this.detail.evalmsg = this.evaluate.val ? '满意' : '不满意'
        this.toggleEvaluate()
      })
    },
    'evaluate.val': function (val) {
      if (val == -1) return
    },
    'detail.tabId': function () {
      this.$nextTick(() => {
        setTimeout(() => {
          let offset = this.$refs['ctrl'].getBoundingClientRect()
          this.ctrlTop = offset.top + 20 + 'px'
        }, 300)
      })
    },
    'detail.evalmsg': function(val) {
      if(val === '不满意') {
        this.showUnpleased = true
      } else {
        this.showUnpleased = false
      }
    },
    showHelp: function (val) {
      if (val) {
        setTimeout(() => {
          this.showCtrl = true
        }, 0)
      } else {
        this.showCtrl = false
      }
    }
  },
  created: function () {
    if (this.expid) {
      this.login(() => {
        this.getDetail()
        this.getAuth()
      }, () => {
        this.goLogin()
      })
    } else {
      this.navigator.replace({
        name: "orderList"
      })
    }
  },
  beforeDestroy: function () {
    this.timer && clearInterval(this.timer)
  }
}